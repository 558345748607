<template>
  <div class="flex flex-row items-center space-x-4">
    <div class="h-1.5 rounded-full bg-gray-200 flex-grow overflow-hidden relative">
      <div
        class="w-full h-full rounded-10 bg-blue-200 absolute left-0 top-0"
        :style="{
          transform: `translateX(-${100 - value}%)`,
        }"
      />
    </div>
    <span class="text-sm text-tertiary font-semibold">{{ _value }}%</span>
  </div>
</template>

<script>
export default {
  name: 'VendorProfileProgress',
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    _value() {
      if (this.value > 100) {
        return 100;
      }
      if (this.value < 0) {
        return 0;
      }
      return parseInt(this.value, 10);
    },
  },
};
</script>
